export const IDS = {
  organizationTypes: {
    Distributor: '138c5098-41ff-45c7-b08a-98c0cae54a17',
    Retailer: '5c94f6a2-f82b-437c-9a99-a54544d75ff0',
    Dealer: '5232242e-a439-4f62-b292-ec711f4e0656',
  },
  userTypes: {
    ContactPerson: 'd0f7dfe4-c6e0-46a1-b78b-f4484c9125b5',
    Owner: '121380be-fde4-4b3b-a6aa-98b543d529cf',
    Driver: '4f0d486f-436b-4714-bcd5-5de71211cdfa',
    Employee: '75475cf8-bbb4-457a-bbe1-47914cd1bad3',
    Manager: 'd57c973a-43e3-4f9b-bf52-bed58663c0cb',
    Director: 'ba29fee5-43e9-4167-b646-ea283843ebef',
    Admin: '378604de-4495-4ea4-80b8-b6753499b3d0',
    SalesMarketingManager: 'f8e1396e-33a0-4b4f-a871-6127daaa2104',
    AreaSalesManager: '6dc9c226-e811-4437-960b-7fe03aa57168',
    SalesRepresentative: '7fdab7a9-c1ee-4543-aa20-b129f5bdf6d1',
    SalesOfficer: '13182ece-6cbd-44ce-b7ae-0ffabef9c0dc',
    PilotSalesman: '598539c9-dae5-44ab-8a02-975e960a415f',
    Customer: '4d6c0797-b970-444d-b51d-f8f042e1df09',
    Staff: 'dea47203-45fd-46df-bdb3-63ca58a4549b'
  },
  unitType: {
    Box: '42bb06ee-02dc-4192-9333-e7b95e6b7f72',
    Piece: '668741ca-f430-408f-b69c-1a4a9f0ed7a6',
  },
  userPriceList: {
    Default: 'e93844c0-2b6b-430e-88ee-d79ada514ac5',
    DistributorSurat: 'a2c4fa46-cc1c-4896-ba4b-9b6b4d321483',
    DealerSurat: '069a085f-6981-4c45-9712-aa16ebf395f8',
    RetailerSurat: '96bef4ac-4699-4006-ac6f-99dccb435e20'
  },
  orderStatus: {
    AccountVerification: '1efd25a9-0497-4179-acc6-7b8a06739981',
    PendingForDispatch: 'e462073c-b220-44a1-8e20-90f87910ff96',
    Dispatched: '9ab2279d-afb0-45ac-a20e-024721130337',
    Delivered: 'e1db99dc-ab53-4ca0-84ef-b85db77dae9d',
    Rejected: '90abf86d-8fbd-42d7-82d6-262689b70e4b',
    Canceled: '57615d55-454b-488b-8b65-25617daf6027',
  },
  TripOrderStatus: {
    OrderPlaced: '40619378-32c4-42ae-8cc2-52e1453c2fc9',
    Dispatched: '7c83925c-f790-4ed2-beeb-f4bb49b8ee32',
    Delivered: '87551d9d-b04a-4ba1-9ac0-d12751e8d2da',
  },

  organizations: {
    RAJ_WAFERS_MASTER: '1d49a2fe-ca28-41bc-9bc5-76331d5e0416',
  },
  DUMMY_ID: '74eed96c-0751-4559-90eb-0e3f3ea27b21',
  PreOrderSalesBy: {
    PilotSalesman: 1,
    Self: 2
  },
}

IDS['OrderStatusColor'] = {
  [IDS.orderStatus.AccountVerification]: '#05399e',
  [IDS.orderStatus.PendingForDispatch]: '#fa9600',
  [IDS.orderStatus.Dispatched]: '#1892ad',
  [IDS.orderStatus.Delivered]: '#09974a',
  [IDS.orderStatus.Canceled]: '#fc2b2b',
  [IDS.orderStatus.Rejected]: '#c31807',
  'DEFAULT': '#000000'
}

IDS['TripOrderStatusColor'] = {
  [IDS.TripOrderStatus.OrderPlaced]: '#fa9600',
  [IDS.TripOrderStatus.Dispatched]: '#1892ad',
  [IDS.TripOrderStatus.Delivered]: '#09974a',
  'DEFAULT': '#000000'
}