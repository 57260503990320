import gql from 'graphql-tag'

function generateQuery (tableName) {
  return gql`mutation ($id:uuid!) {
  delete_${tableName}(where :{id: {_eq: $id}}){
    returning{
      id
    }
  }  
}`
}

export async function deleteRecordById (that, id, tableName) {
  return await that.$apollo.mutate({
    mutation: generateQuery(tableName),
    variables: { id: id },
  })
}

export function generate_UPDATE_RECORD(table) {
  return gql`
 mutation update_${table}($id: uuid!, $obj: ${table}_set_input) {
 update_${table} (
    where: {id: {_eq: $id}}
    _set: $obj
  ) {
    returning {
      id
     }
    }

  }
`
}