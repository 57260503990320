<template>
  <div id="toaster">
    <v-snackbar :color="toaster.color" v-model="toaster.enable" :timeout="3000" top right>
      {{ toaster.message ? toaster.message : 'Something went Wrong' }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'Snackbar',
  props: ['toaster'],
}
</script>
